/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/strict-boolean-expressions */
import {
  Alert,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from "yup";
import type { Credentials } from "../../api/login";
import { Email, Password } from "@mui/icons-material";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const validationSchema: yup.ObjectSchema<Credentials> = yup.object({
  username: yup.string().defined().email(),
  password: yup.string().defined(),
});

export function LoginForm(): JSX.Element {
  const { login, loading } = useAuth();

  const formik = useFormik<Credentials>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (credentials) => {
      void login(credentials);
    },
  });

  function hasError(field: keyof Credentials): boolean {
    return Boolean(formik.touched[field]) && Boolean(formik.errors[field]);
  }

  function isTouched(): boolean {
    let field: keyof Credentials;
    for (field in formik.touched) {
      if (formik.touched[field] === true) {
        return true;
      }
    }
    return false;
  }

  function errorMessage(): string {
    if (hasError("username") && hasError("password")) {
      return "Wrong email or password";
    }
    if (hasError("username")) {
      return "Wrong email";
    }
    if (hasError("password")) {
      return "Wrong password";
    }
    return "";
  }

  const loginTitle = "Login";
  const loginDescription = "Let's get in to Shinobit IoT Database Monitor!";
  const loginEmail = "Email";
  const loginPassword = "Password";
  const loginButtonCaption = "Login";

  return (
    <form data-testid="login-form" onSubmit={formik.handleSubmit}>
      <Stack spacing={6} m={4}>
        <Box>
          <Typography variant="h2" display="block">
            {loginTitle}
          </Typography>
          <Typography variant="body2" mt={1}>
            {loginDescription}
          </Typography>
        </Box>

        <Stack spacing={2} mt={2} mb={2}>
          <TextField
            id="username-input"
            name="username"
            label={loginEmail}
            autoComplete="username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={hasError("username")}
          />
          <TextField
            id="password-input"
            name="password"
            type="password"
            label={loginPassword}
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={hasError("password")}
          />

          {isTouched() && !formik.isValid ? (
            <Alert severity="error" sx={{ paddingLeft: 0 }}>
              {errorMessage()}
            </Alert>
          ) : null}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <LoadingButton
            id="submit-login-button"
            loading={loading}
            type="submit"
            sx={{ minWidth: "200px" }}
            variant="contained"
          >
            {loginButtonCaption}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}
