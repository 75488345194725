import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme } from "./theme/DarkTheme";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { AuthContextProvider } from "./auth/AuthContext";
import { ProtectedRoute } from "./ProtectedRoute";
import Monitor from "./views/monitor/Monitor";
import Login from "./views/login/Login";
import PageHeader from "./components/PageHeader/PageHeader";
import { CustomToastContainer } from "./ToastContainer";

import { LicenseInfo } from "@mui/x-license-pro";
import { SettingsContextProvider } from "./context/SettingsContext";

function App(): JSX.Element {
  LicenseInfo.setLicenseKey(
    "0821e9bba1d85844ceb12618a2aa097bTz01Nzc0MyxFPTE3MDU0MDYzMTU0OTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
      <BrowserRouter>
        <Outlet />
        <CustomToastContainer />
        <AuthContextProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route
              element={
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              }
            >
              <Route path="/" element={<Navigate replace to="/monitor" />} />
              <Route
                path="monitor"
                element={
                  <div>
                    <PageHeader />
                    <SettingsContextProvider>
                      <Monitor />
                    </SettingsContextProvider>
                  </div>
                }
              />
            </Route>
            <Route path="*" element={<Monitor />} />
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
