import { buildUrl, get } from "./api";

export interface Device {
  id: string,
  deviceName: string,
  deviceId: string
}

export async function getDevices(
  token: string,
  parentId?: string | undefined
): Promise<Device[]> {
  let params: Record<string, number | string> | undefined;
  if (parentId !== undefined) {
    params = {parentId};
  }
  return get(
    token,
    buildUrl(`Device`, params)
  );
}
