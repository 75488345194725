import { createTheme, type PaletteOptions } from "@mui/material/styles";

const backgroundDefaultColor = "#161717";

const secondaryColor = "#333333";

const errorMainColor = "#DB5945";

const paletteOptions: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#4066F6",
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: backgroundDefaultColor,
    paper: "#252626",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#848585",
    disabled: "#545556",
  },
  divider: "#2D2E2F",
};

const theme = createTheme();

export const darkTheme = createTheme({
  palette: paletteOptions,
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      letterSpacing: "0",
      [theme.breakpoints.up("xl")]: {
        fontSize: rem(96),
        lineHeight: 1,
      },
      [theme.breakpoints.between("md", "xl")]: {
        fontSize: rem(72),
        lineHeight: 1,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: rem(46),
        lineHeight: lineHeight(46, 56),
      },
    },
    h2: {
      [theme.breakpoints.up("xl")]: {
        fontSize: rem(64),
        lineHeight: 1,
        letterSpacing: "0.01rem",
      },
      [theme.breakpoints.between("md", "xl")]: {
        fontSize: rem(52),
        lineHeight: lineHeight(52, 54),
        letterSpacing: "0.01rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: rem(38),
        lineHeight: lineHeight(38, 48),
        letterSpacing: "0.02rem",
      },
    },
    h3: {
      [theme.breakpoints.up("xl")]: {
        fontSize: rem(48),
        lineHeight: lineHeight(48, 52),
        letterSpacing: "0.02rem",
      },
      [theme.breakpoints.between("md", "xl")]: {
        fontSize: rem(36),
        lineHeight: lineHeight(36, 40),
        letterSpacing: "0.02rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: rem(29),
        lineHeight: lineHeight(29, 36),
        letterSpacing: "0.03rem",
      },
    },
    h4: {
      lineHeight: 1,
      letterSpacing: "0.03rem",
      [theme.breakpoints.up("xl")]: {
        fontSize: rem(32),
        lineHeight: lineHeight(32, 36),
      },
      [theme.breakpoints.between("md", "xl")]: {
        fontSize: rem(28),
        lineHeight: lineHeight(28, 32),
      },
      [theme.breakpoints.down("md")]: {
        fontSize: rem(24),
        lineHeight: lineHeight(24, 32),
      },
    },
    h5: {
      lineHeight: 1,
      letterSpacing: "0.03rem",
      [theme.breakpoints.up("xl")]: {
        fontSize: rem(24),
        lineHeight: lineHeight(24, 28),
      },
      [theme.breakpoints.between("md", "xl")]: {
        fontSize: rem(22),
        lineHeight: lineHeight(22, 26),
      },
      [theme.breakpoints.down("md")]: {
        fontSize: rem(21),
        lineHeight: lineHeight(21, 26),
      },
    },
    h6: {
      fontSize: rem(20),
      lineHeight: lineHeight(20, 24),
      letterSpacing: "0rem",
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: rem(18),
      lineHeight: lineHeight(18, 24),
      letterSpacing: "0.02rem",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontSize: rem(16),
      lineHeight: lineHeight(16, 20),
      letterSpacing: "0.02rem",
    },
    body1: {
      fontSize: rem(18),
      lineHeight: lineHeight(18, 29),
      letterSpacing: "0.02rem",
    },
    body2: {
      fontSize: rem(16),
      lineHeight: lineHeight(16, 24),
      letterSpacing: "0.02rem",
      color: paletteOptions.text?.secondary,
    },
    button: {
      fontSize: rem(18),
      lineHeight: lineHeight(18, 20),
      letterSpacing: "0.03rem",
    },
    caption: {
      fontSize: rem(16),
      lineHeight: lineHeight(16, 22),
      letterSpacing: "0.02rem",
    },
    overline: {
      fontSize: rem(14),
      lineHeight: lineHeight(14, 20),
      letterSpacing: "0.2rem",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: rem(18),
          lineHeight: lineHeight(18, 24),
          letterSpacing: "0.02rem",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // Without this, autocompleted inputs have a blue background (which is actually an inset box shadow)
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 100px ${backgroundDefaultColor} inset`,
          },
          "&:autofill": {
            WebkitBoxShadow: `0 0 0 100px ${backgroundDefaultColor} inset`,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: paletteOptions.text?.primary,
          backgroundColor: secondaryColor,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          color: errorMainColor,
        },
        root: {
          backgroundColor: paletteOptions.background?.default,
          fontSize: rem(14),
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: `0 ${theme.spacing(1)}`,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: paletteOptions.background?.paper,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: paletteOptions.text?.secondary,
          backgroundColor: paletteOptions.background?.default,
          paddingBottom: 0,
        },
        root: ({ theme }) => ({
          ":first-of-type": {
            borderTopLeftRadius: theme.spacing(0.5),
            borderBottomLeftRadius: theme.spacing(0.5),
          },
          ":last-of-type": {
            borderTopRightRadius: theme.spacing(0.5),
            borderBottomRightRadius: theme.spacing(0.5),
          },
          borderBottom: "none",
          padding: theme.spacing(3),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiMenu: {
      // Remove opaqueness from disabled menu items.
      styleOverrides: {
        paper: {
          // TODO: Can removing opaqueness from disabled menu items be done more elegantly?
          "& .MuiList-root": {
            "& .MuiButtonBase-root.Mui-disabled": {
              opacity: 1.0,
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: () => ({paddingLeft: "16px", paddingRight: "16px"})
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: () => ({backgroundColor: backgroundDefaultColor, border: '1px solid rgba(255, 255, 255, 0.08)'})
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: () => ({backgroundColor: backgroundDefaultColor, paddingLeft: 28, paddingRight: 28})
      }
    }
  },
});

// Assume default theme fontSize and htmlFontSize
function rem(targetFontSizePx: number): string {
  const rem = (targetFontSizePx / 16).toFixed(4);
  return `${rem}rem`;
}

function lineHeight(fontSizePx: number, targetLineHeightPx: number): number {
  return targetLineHeightPx / fontSizePx;
}
