import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { assertDefined } from "../assertDefined";
import { toast } from "react-toastify";
import { type Credentials, postLogin } from "../api/login";
import { useNavigate, useSearchParams } from "react-router-dom";

export interface AuthContextProps {
  login: (credentials: Credentials) => Promise<void>;
  logout: () => void;
  readonly token?: string;
  loading: boolean;
  readonly isLoggedIn: boolean;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  assertDefined(context, "AuthContext is not initialized.");
  return context;
}

export function AuthContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const storageKey = "auth-token";

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | undefined>(
    () => localStorage.getItem(storageKey) ?? undefined
  );
  const [isLoggedIn, setIsLoggedIn] = useState(token !== undefined);

  const login = useCallback(
    async (credentials: Credentials) => {
      try {
        setLoading(true);

        const token = await postLogin(credentials);
        setToken(token);
        setIsLoggedIn(true);
        localStorage.setItem(storageKey, token);

        const destination = searchParams.get("destination");
        if (destination === null) {
          navigate("/locations");
        } else {
          const decoded = decodeURIComponent(destination);
          navigate(decoded);
        }
      } catch {
        toast.error("Login failed");
      } finally {
        setLoading(false);
      }
    },
    [searchParams, navigate]
  );

  const logout = useCallback(() => {
    localStorage.removeItem(storageKey);
    setToken(undefined);
    setIsLoggedIn(false);
  }, []);

  const context: AuthContextProps = useMemo(
    () => ({
      token,
      loading,
      login,
      logout,
      isLoggedIn,
    }),
    [isLoggedIn, loading, login, logout, token]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
