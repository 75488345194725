import { type DependencyList, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthToken } from "../auth/useAuthToken";

export interface UseApiResult {
  isLoading: boolean;
}

export type ApiCallback = (token: string) => Promise<void>;

export function useApi(
  callback: ApiCallback,
  deps: DependencyList,
  errorMessage: string
): UseApiResult {
  const token = useAuthToken();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function load(): Promise<void> {
      try {
        setIsLoading(true);
        await callback(token);
      } catch {
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }

    // TODO use async effect package to handle race conditions and unmounting gracefully
    void load();
    // ESLint cannot check dynamic dependencies and gives false warning.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, ...deps]);

  return {
    isLoading,
  };
}
