import { Toolbar } from "@mui/material";
import Logo from "./Logo";

export function PageHeader(): JSX.Element {
  return (
    <Toolbar disableGutters>
      <Logo />
    </Toolbar>
  );
}

export default PageHeader;
