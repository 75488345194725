import { buildUrl, get } from "./api";
import type { DateRange } from "@mui/x-date-pickers-pro";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Measurements = Record<string, number>;

export interface MeasurementSearchParams {
  deviceId: string;
  sensorId: string;
  time: DateRange<Date>;
  step: string;
}

export async function getMeasurements(
  token: string,
  searchParams: MeasurementSearchParams
): Promise<Measurements> {
  const { deviceId, sensorId, time, step } = searchParams;
  return get(
    token,
    buildUrl(`Data`, {
      deviceId,
      sensorId,
      startTime: (time[0]?.getTime() ?? 0) / 1000,
      endTime: (time[1]?.getTime() ?? 0) / 1000,
      step,
    })
  );
}
