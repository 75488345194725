import { buildUrl, throwIfNotOk } from "./api";

export interface Credentials {
  username: string;
  password: string;
}

export async function postLogin(credentials: Credentials): Promise<string> {
  const method = "POST";
  const url = buildUrl("account/login");

  const response = await fetch(url, {
    method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });

  throwIfNotOk(response, method, url);

  return response.text();
}
