import type { PropsWithChildren } from "react";
import { useAuth } from "./auth/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

export function ProtectedRoute({ children }: PropsWithChildren): JSX.Element {
  const { isLoggedIn } = useAuth();
  const { pathname, search } = useLocation();

  const encoded = encodeURIComponent(pathname + search);

  if (!isLoggedIn) {
    return <Navigate to={`login?destination=${encoded}`} replace />;
  }

  return <>{children}</>;
}
