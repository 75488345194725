import { styled } from "@mui/material";
import {Slide, ToastContainer} from "react-toastify";

const StyledToastContainer = styled(ToastContainer)(() => ({
  bottom: "1em",
  left: "1em",
  transform: "none",
}));

export function CustomToastContainer(): JSX.Element {
  // The goal is to have the toasts positioned bottom left, but have their animation be so that they slide in from the bottom of the screen.
  // This is achieved with a slight hack, by using bottom center positioning and overwriting the toast's position to bottom left.
  return (
    <StyledToastContainer
      position="bottom-center"
      transition={Slide}
      autoClose={false}
    />
  );
}
