import { buildUrl, get } from "./api";

export async function getSensors(
  token: string,
  parentId?: string | undefined
): Promise<string[]> {
  let params: Record<string, number | string> | undefined;
  if (parentId !== undefined) {
    params = {parentId};
  }
  return get(
    token,
    buildUrl(`sensor`, params)
  );
}
