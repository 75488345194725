import {
  Stack,
  Typography,
} from "@mui/material";

export const statusBoxBorder = '1px solid rgba(255, 255, 255, 0.1)';

export interface StatusBoxProps {
  caption: string;
  value: number | undefined;
}

export function StatusBox({caption, value}: StatusBoxProps): JSX.Element {

  function formatNumber(num: number | undefined): string {
    return num?.toLocaleString('fi-FI') ?? '';
  }

  return (
      <Stack sx={{border: statusBoxBorder, minHeight: 100, padding: 2}}>
        <Typography variant="subtitle2">
          {caption}
        </Typography>
        <Typography color="primary" variant="h4" sx={{mt: 1}}>
          {formatNumber(value)}
        </Typography>
      </Stack>
  );
}

export default StatusBox;
