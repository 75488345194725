export function getOptions(data: number[][], name: string): unknown {
    return {
        chart: {
            type: "spline",
            zoomType: "x",
            backgroundColor: "transparent",
            height: 600,
            plotBackgroundColor: "transparent",
        },

        credits: {
            enabled: false,
        },

        title: {
            text: ``,
        },

        accessibility: {
            screenReaderSection: {
                beforeChartFormat:
                    "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
            },
        },

        tooltip: {
            valueDecimals: 2,
        },

        xAxis: {
            type: "datetime",
            labels: {
                style: {fontSize: "12px", color: "#9E9E9E"},
            },
        },

        yAxis: {
            title: "",
            gridLineColor: "#616161",
        },

        legend: {
            enabled: false,
            visible: false,
        },

        series: [
            {
                data,
                lineWidth: 1,
                name,
            },
        ],
    };
}
