export function buildUrl(
  path: string,
  searchParams?: Record<string, number | string>
): string {
  const baseUrl = "https://demo.shinobit.cloud/api/";
  const url = new URL(path, baseUrl);
  if (searchParams)
    for (const [name, value] of Object.entries(searchParams)) {
      url.searchParams.append(name, value.toString());
    }
  return url.href;
}

export async function get<T>(token: string, url: string): Promise<T> {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  throwIfNotOk(response, "GET", url);

  return await response.json() as Promise<T>;
}

export function throwIfNotOk(
  response: Response,
  method: string,
  url: string
): void {
  if (!response.ok) {
    throw new Error(
      `${method} "${url}" failed with ${response.status} ${response.statusText}.`
    );
  }
}
