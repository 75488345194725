import { buildUrl, get } from "./api";

export interface ServicesHealth {
  healthy: boolean;
}

export interface DataServersHealth {
  insert: boolean;
  select: boolean;
  storage: boolean;
}

export interface Status {
  dataPoints: number | undefined;
  ingestionRate: number | undefined;
  requestRate: number | undefined;
  activeSeries: number | undefined;
  deviceCount: number | undefined;
  dataServersHealth: DataServersHealth[];
  servicesHealth: ServicesHealth[];
}

export async function getStatus(
  token: string
): Promise<Status> {
  return get(token, buildUrl(`Status`));
}
