import { buildUrl, get } from "./api";

export interface Company {
  id: string,
  name: string,
  companyId: string
}

export async function getCompanies(
  token: string
): Promise<Company[]> {
  return get(
    token,
    buildUrl(`Company`)
  );
}
