/* eslint-disable react/jsx-props-no-spreading,react/jsx-no-bind */
import type { SyntheticEvent } from "react";
import { useCallback, useState } from "react";
import type {
  AutocompleteRenderInputParams,
  AutocompleteValue,
  TextFieldProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useSettings } from "../../context/SettingsContext";
import { steps } from "../../api/step";
import type { Company } from "../../api/company";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type { Device } from "../../api/device";
import { getOptions } from "./getOptions";
import StatusBox, { statusBoxBorder } from "./StatusBox";
import ServiceRow from "./ServiceRow";

export function Monitor(): JSX.Element {
  const caption = "Monitor";
  const deviceCaption = "Device";
  const timesCaption = "Time Period";
  const to = "to";
  const statusCaption = "Status";
  const dataCaption = "Data";
  const dataPointsCaption = "Data Points";
  const ingestionRateCaption = "Ingestion Rate";
  const requestRateCaption = "Request Rate";
  const activeSeriesCaption = "Active Series";
  const deviceCountCaption = "Device Count";
  const timeSeriesDbCaption = "Time Series Database";
  const servicesCaption = "Shinobit Services";
  const inCaption = "In";
  const outCaption = "Out";
  const storeCaption = "Storage";
  const dataServer1Caption = "DB 1";
  const dataServer2Caption = "DB 2";
  const dataServer3Caption = "DB 3";
  const dataServersTotalCaption = "Overall";
  const runningCaption = "Running";

  const service1Caption = "Service 1";
  const service2Caption = "Service 2";
  const service3Caption = "Service 3";
  const serviceTotalCaption = "Overall";

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpandChange = useCallback(
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const {
    companies,
    devices,
    measurements,
    sensors,
    status,
    selectedTime,
    setSelectedCompany,
    setSelectedDevice,
    setSelectedTime,
    setSelectedStep,
  } = useSettings();

  const handleChange = useCallback(
    (newValue: DateRange<Date>) => {
      setSelectedTime(newValue);
    },
    [setSelectedTime]
  );

  const handleRenderInput = useCallback(
    (startProps: TextFieldProps, endProps: TextFieldProps) => (
      <>
        <TextField {...startProps} fullWidth />
        <Box sx={{ mx: 2 }}>{to}</Box>
        <TextField {...endProps} fullWidth />
      </>
    ),
    []
  );

  const handleAutocompleteRenderCompany = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField {...params} label="Company" />
    ),
    []
  );

  const handleAutocompleteRenderDevice = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField {...params} label="Device" />
    ),
    []
  );

  const handleAutocompleteRenderStep = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField {...params} label="Step" />
    ),
    []
  );

  const handleCompanyChange = useCallback(
    (
      _: unknown,
      company: AutocompleteValue<Company, unknown, unknown, unknown>
    ) => {
      setSelectedCompany(company);
    },
    [setSelectedCompany]
  );

  const handleDeviceChange = useCallback(
    (
      _: unknown,
      device: AutocompleteValue<Device, unknown, unknown, unknown>
    ) => {
      setSelectedDevice(device);
    },
    [setSelectedDevice]
  );

  const handleStepChange = useCallback(
    (_: unknown, step: string | null) => {
      setSelectedStep(step ?? "");
    },
    [setSelectedStep]
  );

  function getInStatus(): number[] {
    const s1 = status.dataServersHealth[0]?.insert ? 2 : 0;
    const s2 = status.dataServersHealth[1]?.insert ? 2 : 0;
    const s3 = status.dataServersHealth[2]?.insert ? 2 : 0;
    const result: number[] = [s1, s2, s3];
    const total = s1 + s2 + s3;
    let overall = 2;
    if (total === 0) overall = 0;
    else if (total < 6) overall = 1;
    result.push(overall);
    return result;
  }

  function getOutStatus(): number[] {
    const s1 = status.dataServersHealth[0]?.select ? 2 : 0;
    const s2 = status.dataServersHealth[1]?.select ? 2 : 0;
    const s3 = status.dataServersHealth[2]?.select ? 2 : 0;
    const result: number[] = [s1, s2, s3];
    const total = s1 + s2 + s3;
    let overall = 2;
    if (total === 0) overall = 0;
    else if (total < 6) overall = 1;
    result.push(overall);
    return result;
  }

  function getStoreStatus(): number[] {
    const s1 = status.dataServersHealth[0]?.storage ? 2 : 0;
    const s2 = status.dataServersHealth[1]?.storage ? 2 : 0;
    const s3 = status.dataServersHealth[2]?.storage ? 2 : 0;
    const result: number[] = [s1, s2, s3];
    const total = s1 + s2 + s3;
    let overall = 2;
    if (total === 0) overall = 0;
    else if (total < 6) overall = 1;
    result.push(overall);
    return result;
  }

  function getServiceStatus(): number[] {
    const s1 = status.servicesHealth[0]?.healthy ? 2 : 0;
    const s2 = status.servicesHealth[1]?.healthy ? 2 : 0;
    const s3 = status.servicesHealth[2]?.healthy ? 2 : 0;
    const result: number[] = [s1, s2, s3];
    const total = s1 + s2 + s3;
    let overall = 2;
    if (total === 0) overall = 0;
    else if (total < 6) overall = 1;
    result.push(overall);
    return result;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" sx={{ mb: 4 }}>
        {caption}
      </Typography>

      <Accordion
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleExpandChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
            {statusCaption}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <StatusBox
                caption={dataPointsCaption}
                value={status.dataPoints}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <StatusBox
                caption={ingestionRateCaption}
                value={status.ingestionRate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <StatusBox
                caption={requestRateCaption}
                value={status.requestRate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <StatusBox
                caption={activeSeriesCaption}
                value={status.activeSeries}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <StatusBox
                caption={deviceCountCaption}
                value={status.deviceCount}
              />
            </Grid>
          </Grid>

          <Stack sx={{ border: statusBoxBorder, mt: 3, padding: 2 }}>
            <Typography variant="subtitle2">{servicesCaption}</Typography>
            <Grid
              container
              columns={10}
              sx={{ borderBottom: statusBoxBorder, padding: 1 }}
            >
              <Grid item xs={2}>
                <Stack direction="row" />
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">{service1Caption}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">{service2Caption}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">{service3Caption}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">
                    {serviceTotalCaption}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <ServiceRow caption={runningCaption} status={getServiceStatus()} border={false} />
          </Stack>

          <Stack sx={{ border: statusBoxBorder, mt: 3, padding: 2 }}>
            <Typography variant="subtitle2">{timeSeriesDbCaption}</Typography>
            <Grid
              container
              columns={10}
              sx={{ borderBottom: statusBoxBorder, padding: 1 }}
            >
              <Grid item xs={2}>
                <Stack direction="row" />
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">
                    {dataServer1Caption}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">
                    {dataServer2Caption}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">
                    {dataServer3Caption}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center">
                  <Typography variant="subtitle2">
                    {dataServersTotalCaption}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <ServiceRow caption={inCaption} status={getInStatus()} border />
            <ServiceRow caption={outCaption} status={getOutStatus()} border />
            <ServiceRow caption={storeCaption} status={getStoreStatus()} border={false} />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleExpandChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
            {dataCaption}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Stack width="100%">
              <Typography variant="h6" sx={{ mb: 2 }}>
                {timesCaption}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    localeText={{ start: "Start", end: "End" }}
                  >
                    <DateRangePicker
                      value={selectedTime}
                      onChange={handleChange}
                      renderInput={handleRenderInput}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-steps"
                    defaultValue="10m"
                    options={steps}
                    fullWidth
                    onChange={handleStepChange}
                    renderInput={handleAutocompleteRenderStep}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack>
              <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
                {deviceCaption}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-companies"
                    options={companies}
                    fullWidth
                    onChange={handleCompanyChange}
                    renderInput={handleAutocompleteRenderCompany}
                    getOptionLabel={(company) => company.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-devices"
                    options={devices}
                    fullWidth
                    onChange={handleDeviceChange}
                    renderInput={handleAutocompleteRenderDevice}
                    getOptionLabel={(device) => device.deviceName}
                  />
                </Grid>
              </Grid>
            </Stack>
            {sensors.map((sensor) => (
              <Box
                key={sensor}
                sx={{
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                  mb: 2,
                  mt: 2,
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions(measurements[sensor], sensor)}
                />
              </Box>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default Monitor;
