/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,no-await-in-loop */
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import type { Company } from "../api/company";
import { getCompanies } from "../api/company";
import type { Device } from "../api/device";
import { getDevices } from "../api/device";
import { assertDefined } from "../assertDefined";
import { useApi } from "../api/useApi";
import { sort } from "fast-sort";
import { getMeasurements } from "../api/measurement";
import type { DateRange } from "@mui/x-date-pickers-pro";
import { getSensors } from "../api/sensor";
import type { Status } from "../api/status";
import { getStatus } from "../api/status";

export interface SettingsContextProps {
  companies: Company[];
  devices: Device[];
  measurements: Record<string, number[][]>;
  sensors: string[];
  status: Status;
  isLoadingCompanies: boolean;
  isLoadingDevices: boolean;
  isLoadingMeasurements: boolean;
  isLoadingSensors: boolean;
  selectedCompany: Company | null;
  setSelectedCompany: (company: Company | null) => void;
  selectedDevice: Device | null;
  setSelectedDevice: (device: Device | null) => void;
  selectedTime: DateRange<Date>;
  setSelectedTime: (time: DateRange<Date>) => void;
  selectedStep: string;
  setSelectedStep: (step: string) => void;
}

export const CompanySelectorContext = createContext<
  SettingsContextProps | undefined
>(undefined);

export function useSettings(): SettingsContextProps {
  const context = useContext(CompanySelectorContext);
  assertDefined(context, "CompanySelectorContext is not initialized.");
  return context;
}

export function SettingsContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [company, setCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [device, setDevice] = useState<Device | null>(null);
  const [devices, setDevices] = useState<Device[]>([]);
  const [sensors, setSensors] = useState<string[]>([
    "Temperature",
    "Co2",
    "Humidity",
  ]);
  const [measurements, setMeasurements] = useState<Record<string, number[][]>>(
    {}
  );
  const [time, setTime] = useState<DateRange<Date>>([null, null]);
  const [step, setStep] = useState<string>("10m");
  const [status, setStatus] = useState<Status>({
    dataPoints: 0,
    ingestionRate: 0,
    requestRate: 0,
    activeSeries: 0,
    deviceCount: 0,
    dataServersHealth: [],
    servicesHealth: [],
  });
  const [statusUpdate, setStatusUpdate] = useState<number>(0);

  const { isLoading: isLoadingCompanies } = useApi(
    async (token) => {
      const companies = await getCompanies(token);
      const sorted: Company[] = sort(companies).asc((company) => company.name);
      setCompanies(sorted);
    },
    [],
    "Company fetch error."
  );

  const { isLoading: isLoadingDevices } = useApi(
    async (token) => {
      if (company) {
        const devices = await getDevices(token, company.id);
        const sorted: Device[] = sort(devices).asc(
          (device) => device.deviceName
        );
        setDevices(sorted);
      }
    },
    [company],
    "Device fetch error"
  );

  useApi(
    async (token) => {
      try {
        const stat = await getStatus(token);
        setStatus(stat);
        setTimeout(() => {
          setStatusUpdate(new Date().getTime());
        }, 15000);
      } catch {
        // do nothing
      }
    },
    [statusUpdate],
    "Status fetch error"
  );

  const { isLoading: isLoadingMeasurements } = useApi(
    async (token) => {
      if (device && time[0] && time[1] && step) {
        const data: Record<string, number[][]> = {};

        for (const sensor of sensors) {
          const result = await getMeasurements(token, {
            deviceId: device.deviceId,
            sensorId: sensor,
            time,
            step,
          });
          const measurements: number[][] = [];
          for (const key of Object.keys(result)) {
            measurements.push([parseInt(key, 10), result[key]]);
          }
          data[sensor] = measurements;
        }

        setMeasurements(data);
      }
    },
    [device, time, step],
    "Measurement fetch error"
  );

  const { isLoading: isLoadingSensors } = useApi(
    async (token) => {
      if (device?.id === "-_") {
        const sensors = await getSensors(token, device.deviceId);
        setSensors(sensors);
      }
    },
    [company],
    "Device fetch error"
  );

  const setSelectedCompany = useCallback((company: Company | null): void => {
    setCompany(company);
  }, []);

  const setSelectedDevice = useCallback((device: Device | null): void => {
    setDevice(device);
  }, []);

  const setSelectedTime = useCallback((time: DateRange<Date>): void => {
    setTime(time);
  }, []);

  const setSelectedStep = useCallback((step: string): void => {
    setStep(step);
  }, []);

  const [context, setContext] = useState<SettingsContextProps>({
    companies,
    devices,
    measurements,
    sensors,
    status,
    selectedDevice: device,
    selectedCompany: company,
    setSelectedDevice,
    setSelectedCompany,
    isLoadingCompanies,
    isLoadingDevices,
    isLoadingMeasurements,
    isLoadingSensors,
    selectedTime: time,
    selectedStep: step,
    setSelectedTime,
    setSelectedStep,
  });

  useEffect(
    () =>
      setContext({
        companies,
        devices,
        measurements,
        sensors,
        status,
        selectedDevice: device,
        selectedCompany: company,
        setSelectedDevice,
        setSelectedCompany,
        isLoadingCompanies,
        isLoadingDevices,
        isLoadingMeasurements,
        isLoadingSensors,
        selectedTime: time,
        selectedStep: step,
        setSelectedTime,
        setSelectedStep,
      }),
    [
      companies,
      devices,
      measurements,
      device,
      company,
      setSelectedDevice,
      setSelectedCompany,
      isLoadingCompanies,
      isLoadingDevices,
      isLoadingMeasurements,
      time,
      step,
      setSelectedTime,
      setSelectedStep,
      sensors,
      status,
      isLoadingSensors,
    ]
  );

  return (
    <CompanySelectorContext.Provider value={context}>
      {children}
    </CompanySelectorContext.Provider>
  );
}
