import { Grid, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { statusBoxBorder } from "./StatusBox";

export interface ServiceRowProps {
  caption: string;
  status: number[];
  border: boolean;
}

export function ServiceRow({ caption, status, border }: ServiceRowProps): JSX.Element {
  return (
    <Grid
      container
      columns={10}
      sx={{ borderBottom: border ? statusBoxBorder : 'none', padding: 1 }}
    >
      <Grid item xs={2}>
        <Stack direction="row">
          <Typography>{caption}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center">
          {status[0] === 2 ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center">
          {status[1] === 2 ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center">
          {status[2] === 2 ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center">
          {status[3] === 2 || status[3] === 1 ? (
            <CheckCircleOutlineIcon
              color={status[3] === 2 ? "success" : "warning"}
            />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ServiceRow;
