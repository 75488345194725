import {Container, Stack, Toolbar} from "@mui/material";
import Logo from "../../components/PageHeader/Logo";
import { LoginForm } from "./LoginForm";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";

function Login(): JSX.Element {
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <Stack>
      <Toolbar>
        <Logo />
      </Toolbar>

      <Container maxWidth="sm">
        <LoginForm />
      </Container>
    </Stack>
  );
}

export default Login;
