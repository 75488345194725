import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";

const element = document.getElementById("root");
if (element === null) {
    throw new Error();
}

const root = ReactDOM.createRoot(element);

// Disable false positive error
// eslint-disable-next-line jest/require-hook
root.render(<App />);
