import { ReactComponent as LogoSvg } from "./Shinobit_logo_white.svg";
import { styled } from "@mui/material";
import { Link } from "../Link";

const LogoIcon = styled(LogoSvg)(({ theme }) => ({
  fill: theme.palette.text.primary,
    height: '80px'
}));

export function Logo(): JSX.Element {
  return (
    <Link to="/">
      <LogoIcon />
    </Link>
  );
}

export default Logo;
